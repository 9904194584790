import { ResponseErrorType } from "../../types/payment"

export const haveMessageInError = (error: unknown): error is { message: string } => {
  return typeof error === "object" && !Array.isArray(error) && error != null && "message" in error
}

export const haveStatusInError = (error: unknown): error is { status: number } => {
  return typeof error === "object" && !Array.isArray(error) && error != null && "status" in error
}

export const errorMessage = (_e: unknown): string => {
  if (haveMessageInError(_e)) {
    return _e.message
  } else {
    return "Exception Error"
  }
}

export const typeGuardAmazonPayError = (error: unknown): error is ResponseErrorType => {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    typeof error.response === "object" &&
    error.response !== null &&
    "data" in error.response
  )
}

export const typeGuardSmaregiError = (error: unknown): error is ResponseErrorType => {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    typeof error.response === "object" &&
    error.response !== null &&
    "data" in error.response &&
    typeof error.response.data === "object" &&
    error.response.data !== null &&
    "detail" in error.response.data
  )
}

export const keyInObject = <T = unknown>(object: unknown, key: string): object is Record<typeof key, T> => {
  return typeof object === "object" && !Array.isArray(object) && object !== null && key in object
}
